<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{ $t("betDetail.title") }} #{{ item.uuid || "No transaction ID" }}
    </template>
    <div v-if="item.gameType === 'Dragon Tiger'">
      <div class="text-muted">
        <div class="table-responsive mb-0">
          <div class="w-100">
            <p class="mb-1">{{ $t("betDetail.date") }}:</p>
            <h5 class="font-size-16">
              <div class="createdAt" @mouseover="handleOver" @mouseleave="handleLeave">
                {{ moment(item.createdAt).format("DD/MM/YYYY | HH:mm:ss") }}
                <span class="badge badge-time bg-primary rounded-pill">Server time:
                  {{
                    moment.utc(item.createdAt).format("DD/MM/YYYY | HH:mm:ss")
                  }}</span>
              </div>
            </h5>
          </div>
          <div class="w-100 my-2 d-flex row" v-if="Object.entries(item).length && item.round.result">
            <strong>{{ $t("betDetail.result") }}:</strong>
            <div class="d-flex justify-content-start">
              <div class="mx-4">
                <p class="text-center fw-bold">Dragon</p>
                <div class="card-accepted-container" :style="`color:${item.round.result.card1.color}`">
                  <div class="card-accepted">
                    <span class="card-left-number">{{
                      item.round.result.card1.name
                    }}</span>
                    <span class="card-type">{{
                      cardTypes[item.round.result.card1.type]
                    }}</span>
                    <span class="card-right-number">{{
                      item.round.result.card1.name
                    }}</span>
                  </div>
                </div>
              </div>
              <div>
                <p class="text-center fw-bold">Tiger</p>
                <div class="card-accepted-container" :style="`color:${item.round.result.card2.color}`">
                  <div class="card-accepted">
                    <span class="card-left-number">{{
                      item.round.result.card2.name
                    }}</span>
                    <span class="card-type">{{
                      cardTypes[item.round.result.card2.type]
                    }}</span>
                    <span class="card-right-number">{{
                      item.round.result.card2.name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p class="mb-1">{{ $t("transactions.roundID") }} :</p>
            <h5 class="font-size-16">
              {{
                Object.entries(item).length && item.round
                ? item.round.identifierNumber
                : "No round"
              }}
            </h5>
          </div>
          <div v-if="Object.entries(item).length">
            <p class="mt-3 mb-1">{{ $t("betDetail.roulette") }} :</p>
            <h5 class="font-size-16">{{ item.game.name }}</h5>
          </div>
          <div v-if="Object.entries(item).length">
            <p class="mt-3 mb-1">{{ $t("betDetail.user") }}:</p>
            <h5 class="font-size-16">{{ item.player.username }}</h5>
          </div>
          <div v-if="Object.entries(item).length">
            <p class="mt-3 mb-1">{{ $t("tableReports.operator") }}:</p>
            <h5 class="font-size-16">{{ item.player.operator.name }}</h5>
          </div>
          <div v-if="Object.entries(item).length">
            <p class="mt-3 mb-1">{{ $t("filter.client") }}:</p>
            <h5 class="font-size-16">
              {{ item.client.name }}
            </h5>
          </div>
          <div v-if="Object.entries(item).length">
            <b-table :items="item.betDetail" :fields="fields">
              <template #cell(type)="data">
                <strong>{{ data.item.type }}</strong>
              </template>
              <template #cell(dragon)="data">{{
                data.item.dragon
                ? data.item.dragon
                : "0"
              }}</template>
              <template #cell(tiger)="data">{{
                data.item.tiger
                ? data.item.tiger
                : "0"
              }}</template>
              <template #cell(tie)="data">{{
                data.item.tie
                ? data.item.tie
                : "0"
              }}</template>
              <template #cell(perfectTie)="data">{{
                data.item.perfectTie
                ? data.item.perfectTie
                : "0"
              }}</template>
              <template #cell(big)="data">{{
                data.item.big
                ? `${data.item.big.amount}-${data.item.big.winner}`
                : "0"
              }}</template>
              <template #cell(small)="data">{{
                data.item.small
                ? `${data.item.small.amount}-${data.item.small.winner}`
                : "0"
              }}</template>
              <template #cell(even)="data">{{
                data.item.even
                ? `${data.item.even.amount}-${data.item.even.winner}`
                : "0"
              }}</template>
              <template #cell(odd)="data">{{
                data.item.odd
                ? `${data.item.odd.amount}-${data.item.odd.winner}`
                : "0"
              }}</template>
              <template #cell(black)="data">{{
                data.item.black
                ? `${data.item.black.amount}-${data.item.black.winner}`
                : "0"
              }}</template>
              <template #cell(red)="data">{{
                data.item.red
                ? `${data.item.red.amount}-${data.item.red.winner}`
                : "0"
              }}</template>
            </b-table>
            <p class="d-flex justify-content-between mx-2 text-black fw-bold" v-if="typeof item.earnings === 'number'">
              <span>TOTAL GANANCIAS:</span>
              <span>{{ item.earnings }}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- <div
        v-if="item.type === 'credit'"
        class="d-flex justify-content-between fw-bolder mx-auto w-75"
      >
        <p>{{ $t("tableReports.totalBet") }}:</p>
        <p>
          {{ item.bet.totalAmount }}
          {{ item.currency && item.currency.short }}
        </p>
      </div>
      <div class="d-flex justify-content-between fw-bolder mx-auto w-75">
        <p>
          {{
            item.type === "credit"
              ? $t("tableReports.totalWin") + ":"
              : $t("tableReports.totalBet") + ":"
          }}
        </p>
        <p>{{ item.amount }} {{ item.currency && item.currency.short }}</p>
      </div> -->
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      modal: false,
      cardTypes: {
        trebol: "♣",
        pica: "♠",
        corazon: "♥",
        diamante: "♦",
      },
      fields: [
        {
          key: "type",
          label: "type".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "dragon",
          label: "dragon".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "tiger",
          label: "tiger".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "tie",
          label: "tie".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "perfectTie",
          label: "perfectTie".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "big",
          label: "big".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "small",
          label: "small".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "even",
          label: "even".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "odd",
          label: "odd".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "black",
          label: "black".toUpperCase(),
          class: "space-nowrap text-center"
        },
        {
          key: "red",
          label: "red".toUpperCase(),
          class: "space-nowrap text-center"
        },
      ],
    };
  },
  methods: {
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
  },
};
</script>

<style scoped>
.card-accepted-container {
  display: grid;
  justify-content: center;
}

.card-accepted {
  background-color: rgb(231, 231, 231);
  box-shadow: 0 0 20px rgb(100, 100, 100);
  display: grid;
  border-radius: 5px;
  height: 120px;
  width: 60px;
  /* padding: 10px; */
  grid-template-columns: 30% 40% 30%;
  font-weight: bolder;
  font-size: 1rem;
  grid-template-rows: repeat(3, 33.3%);
}

.card-type {
  grid-row: 2/3;
  grid-column: 2/3;
  justify-self: center;
  font-size: 2rem;
  align-self: center;
}

.card-right-number {
  grid-row: 3/4;
  grid-column: 3/4;
  justify-self: end;
  align-self: end;
}
</style>
