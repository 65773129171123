<template>
  <div>
    <vs-select multiple v-model="value" class="shadow-lg">
      <vs-option label="CREDIT" value="credit"> CREDIT </vs-option>
      <vs-option label="DEBIT" value="debit"> DEBIT</vs-option>
      <vs-option label="ASC: MONTO" value="amount"> ASC: MONTO</vs-option>
    </vs-select>
  </div>
</template>

<script>
export default {
  emits: ["update:typeTransaction"],
  data() {
    return {
      value: [],
      filter: {
        credit: "",
        debit: "",
        amount: "",
      },
    };
  },
  watch: {
    value(newValue) {
      const initialValue = { credit: "", debit: "", amount: "" };

      this.filter = initialValue;

      if (newValue.length) {
        for (let i of newValue) {
          this.filter[i] = i;
        }
      } else {
        this.filter = initialValue;
      }

      this.$emit("update:typeTransaction", { ...this.filter });
    },
  },
};
</script>

<style lang="scss" scoped></style>
